import { GrafanaThemeCommons } from '@grafana/data';

// Soracom color palette
export const soracomColorsPalette = {
  celeste950: '#062022',
  celeste900: '#0a3639',
  celeste800: '#0e494d',
  celeste700: '#15696e',
  celeste600: '#209ea6',
  celeste: '#34cdd7',
  celeste400: '#68dde4',
  celeste300: '#a3ecf1',
  celeste200: '#c9f5f8',
  celeste100: '#e7fbfd',
  celeste50: '#f6fefe',
  ink950: '#0f1419',
  ink900: '#1a2129',
  ink800: '#242d37',
  ink700: '#34414e',
  ink600: '#506275',
  ink: '#718599',
  ink400: '#91a6ba',
  ink300: '#b8c9da',
  ink200: '#d4e1ed',
  ink100: '#eaf2f9',
  ink50: '#f7fafd',
  gray950: '#161717',
  gray900: '#242627',
  gray800: '#313335',
  gray700: '#474a4c',
  gray600: '#6b6f72',
  gray: '#929699',
  gray400: '#afb2b5',
  gray300: '#cfd1d2',
  gray200: '#e4e5e6',
  gray100: '#f3f3f4',
  gray50: '#fbfbfb',
  blue950: '#061222',
  blue900: '#0a1f39',
  blue800: '#0e2a4d',
  blue700: '#153c6e',
  blue600: '#205ba6',
  blue: '#347cd7',
  blue400: '#689fe4',
  blue300: '#a3c5f1',
  blue200: '#c9def8',
  blue100: '#e7f0fd',
  blue50: '#f6fafe',
  green950: '#031d10',
  green900: '#05311b',
  green800: '#074224',
  green700: '#0a5f34',
  green600: '#0f8f4f',
  green: '#15c16b',
  green400: '#3aeb93',
  green300: '#87f5be',
  green200: '#bafada',
  green100: '#e0fdef',
  green50: '#f4fff9',
  magenta950: '#22061b',
  magenta900: '#390a2c',
  magenta800: '#4d0e3c',
  magenta700: '#6e1556',
  magenta600: '#a62082',
  magenta: '#d734ab',
  magenta400: '#e468c3',
  magenta300: '#f1a3db',
  magenta200: '#f8c9eb',
  magenta100: '#fde7f7',
  magenta50: '#fef6fc',
  red950: '#220806',
  red900: '#390d0a',
  red800: '#4d120e',
  red700: '#6e1a15',
  red600: '#a62820',
  red: '#d73e34',
  red400: '#e47068',
  red300: '#f1a7a3',
  red200: '#f8ccc9',
  red100: '#fde8e7',
  red50: '#fef6f6',
  yellow950: '#252406',
  yellow900: '#3e3c0b',
  yellow800: '#54520f',
  yellow700: '#797516',
  yellow600: '#b6b121',
  yellow: '#dcd747',
  yellow400: '#e7e477',
  yellow300: '#f2f0ab',
  yellow200: '#f9f7cf',
  yellow100: '#fdfce9',
  yellow50: '#fefef7',
  orange950: '#251400',
  orange900: '#3d2100',
  orange800: '#5c2900',
  orange700: '#8a4000',
  orange600: '#cc6300',
  orange: '#f58300',
  orange400: '#ffa53e',
  orange300: '#ffca7a',
  orange200: '#ffe0b3',
  orange100: '#ffeed1',
  orange50: '#fff9eb',
};

export const commonColorsPalette = {
  // New greys palette used by next-gen form elements
  gray98: soracomColorsPalette.gray50,
  gray97: soracomColorsPalette.gray50,
  gray95: soracomColorsPalette.gray100,
  gray90: soracomColorsPalette.gray200,
  gray85: soracomColorsPalette.gray300,
  gray70: soracomColorsPalette.gray400,
  gray60: soracomColorsPalette.gray,
  gray33: soracomColorsPalette.gray600,
  gray25: soracomColorsPalette.gray700,
  gray15: soracomColorsPalette.gray800,
  gray10: soracomColorsPalette.gray900,
  gray05: soracomColorsPalette.gray950,

  // New blues palette used by next-gen form elements
  blue95: soracomColorsPalette.blue300, // blue95
  blue85: soracomColorsPalette.blue400, // blueText
  blue80: soracomColorsPalette.blue, // blue80
  blue77: soracomColorsPalette.blue600, // blue77

  // New reds palette used by next-gen form elements
  red88: soracomColorsPalette.red600,
};

const SPACING_BASE = 8;

const theme: GrafanaThemeCommons = {
  name: 'Grafana Default',
  typography: {
    fontFamily: {
      sansSerif: "'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif",
      monospace: "'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace",
    },
    size: {
      base: '14px',
      xs: '10px',
      sm: '12px',
      md: '14px',
      lg: '18px',
    },
    heading: {
      h1: '32px',
      h2: '26px',
      h3: '21px',
      h4: '18px',
      h5: '16px',
      h6: '14px',
    },
    weight: {
      light: 300,
      regular: 400,
      semibold: 600,
      bold: 700,
    },
    lineHeight: {
      xs: 1,
      sm: 1.1,
      md: 1.5,
      lg: 2,
    },
    link: {
      decoration: 'none',
      hoverDecoration: 'none',
    },
  },
  breakpoints: {
    xs: '0',
    sm: '544px',
    md: '769px', // 1 more than regular ipad in portrait
    lg: '992px',
    xl: '1200px',
    xxl: '1440px',
  },
  spacing: {
    base: SPACING_BASE,
    insetSquishMd: '4px 8px',
    d: '16px',
    xxs: '2px',
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    gutter: '30px',

    // Next-gen forms spacing variables
    // TODO: Move variables definition to respective components when implementing
    formSpacingBase: SPACING_BASE,
    formMargin: `${SPACING_BASE * 4}px`,
    formFieldsetMargin: `${SPACING_BASE * 2}px`,
    formInputHeight: SPACING_BASE * 4,
    formButtonHeight: SPACING_BASE * 4,
    formInputPaddingHorizontal: `${SPACING_BASE}px`,

    // Used for icons do define spacing between icon and input field
    // Applied on the right(prefix) or left(suffix)
    formInputAffixPaddingHorizontal: `${SPACING_BASE / 2}px`,

    formInputMargin: `${SPACING_BASE * 2}px`,
    formLabelPadding: '0 0 0 2px',
    formLabelMargin: `0 0 ${SPACING_BASE / 2 + 'px'} 0`,
    formValidationMessagePadding: '4px 8px',
    formValidationMessageMargin: '4px 0 0 0',
    inlineFormMargin: '4px',
  },
  border: {
    radius: {
      sm: '2px',
      md: '3px',
      lg: '5px',
    },
    width: {
      sm: '1px',
    },
  },
  height: {
    sm: 24,
    md: 32,
    lg: 48,
  },
  panelPadding: 8,
  panelHeaderHeight: 28,
  zIndex: {
    navbarFixed: 1000,
    sidemenu: 1020,
    dropdown: 1030,
    typeahead: 1030,
    tooltip: 1040,
    modalBackdrop: 1050,
    modal: 1060,
  },
};

export default theme;
