import defaultTheme, { commonColorsPalette, soracomColorsPalette } from './default';
import { GrafanaThemeType, GrafanaTheme } from '@grafana/data';

const basicColors = {
  ...commonColorsPalette,
  black: '#000000',
  white: '#ffffff',
  dark1: '#141414',
  dark2: '#161719',
  dark3: '#1f1f20',
  dark4: '#212124',
  dark5: '#222426',
  dark6: '#262628',
  dark7: '#292a2d',
  dark8: '#2f2f32',
  dark9: '#343436',
  dark10: '#424345',
  gray1: '#555555',
  gray2: '#8e8e8e',
  gray3: '#b3b3b3',
  gray4: '#d8d9da',
  gray5: '#ececec',
  gray6: '#f4f5f8', // not used in dark theme
  gray7: '#fbfbfb', // not used in dark theme
  redBase: '#e02f44',
  redShade: '#c4162a',
  greenBase: '#299c46',
  greenShade: '#23843b',
  red: '#d44a3a',
  yellow: '#ecbb13',
  purple: soracomColorsPalette.magenta400,
  variable: '#32d1df',
  orange: '#eb7b18',
  orangeDark: '#ff780a',
};

const backgrounds = {
  bg1: soracomColorsPalette.ink900,
  bg2: soracomColorsPalette.ink800,
  bg3: soracomColorsPalette.ink700,
  dashboardBg: soracomColorsPalette.ink950,
  bgBlue1: soracomColorsPalette.celeste,
  bgBlue2: soracomColorsPalette.celeste,
};

const borders = {
  border1: soracomColorsPalette.gray800,
  border2: soracomColorsPalette.gray700,
  border3: soracomColorsPalette.gray600,
};

const textColors = {
  textStrong: soracomColorsPalette.gray50,
  textHeading: basicColors.gray4,
  text: soracomColorsPalette.gray300,
  textSemiWeak: soracomColorsPalette.gray400,
  textWeak: soracomColorsPalette.gray,
  textFaint: soracomColorsPalette.gray600,
  textBlue: soracomColorsPalette.blue400,
};

const form = {
  // Next-gen forms functional colors
  formLabel: textColors.textSemiWeak,
  formDescription: soracomColorsPalette.gray,
  formInputBg: soracomColorsPalette.gray950,
  formInputBgDisabled: soracomColorsPalette.gray900,
  formInputBorder: borders.border2,
  formInputBorderHover: soracomColorsPalette.gray600,
  formInputBorderActive: soracomColorsPalette.blue300,
  formInputBorderInvalid: soracomColorsPalette.red600,
  formInputPlaceholderText: textColors.textFaint,
  formInputText: soracomColorsPalette.gray300,
  formInputDisabledText: soracomColorsPalette.gray400,
  formFocusOutline: soracomColorsPalette.celeste,
  formValidationMessageText: basicColors.white,
  formValidationMessageBg: soracomColorsPalette.red600,
  formSwitchBg: soracomColorsPalette.gray700,
  formSwitchBgActive: soracomColorsPalette.blue300,
  formSwitchBgHover: soracomColorsPalette.gray600,
  formSwitchBgActiveHover: soracomColorsPalette.celeste,
  formSwitchBgDisabled: soracomColorsPalette.gray700,
  formSwitchDot: soracomColorsPalette.gray800,
  formCheckboxBgChecked: soracomColorsPalette.blue300,
  formCheckboxBgCheckedHover: soracomColorsPalette.celeste,
  formCheckboxCheckmark: soracomColorsPalette.gray700,
};

const darkTheme: GrafanaTheme = {
  ...defaultTheme,
  type: GrafanaThemeType.Dark,
  isDark: true,
  isLight: false,
  name: 'Grafana Dark',
  palette: {
    ...basicColors,
    brandPrimary: soracomColorsPalette.celeste,
    brandSuccess: soracomColorsPalette.green,
    brandWarning: soracomColorsPalette.orange,
    brandDanger: soracomColorsPalette.red,
    queryRed: soracomColorsPalette.red,
    queryGreen: soracomColorsPalette.green400,
    queryPurple: soracomColorsPalette.magenta400,
    queryOrange: soracomColorsPalette.orange,
    online: soracomColorsPalette.green,
    warn: soracomColorsPalette.orange,
    critical: soracomColorsPalette.red,
  },
  colors: {
    ...backgrounds,
    ...borders,
    ...form,
    ...textColors,

    bodyBg: backgrounds.bg1,
    panelBg: backgrounds.bg1,
    pageHeaderBg: backgrounds.bg2,
    pageHeaderBorder: borders.border1,
    panelBorder: borders.border1,

    dropdownBg: form.formInputBg,
    dropdownShadow: basicColors.black,
    dropdownOptionHoverBg: backgrounds.bg2,

    // Link colors
    link: basicColors.gray4,
    linkDisabled: basicColors.gray2,
    linkHover: basicColors.white,
    linkExternal: soracomColorsPalette.blue400,
  },
  shadows: {
    listItem: 'none',
  },
};

export default darkTheme;
