import React, { FC, useState } from 'react';
import { css, cx } from 'emotion';
import { useTheme } from '@grafana/ui';

export interface BrandComponentProps {
  operatorId?: string;
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/lagoon-logo-cl.svg" alt="Lagoon" />;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const theme = useTheme();
  const background = css`
    background: url(public/img/login_background_${theme.isDark ? 'dark' : 'light'}.svg);
    background-size: cover;
  `;

  return <div className={cx(background, className)}>{children}</div>;
};

async function imageExists(imageURL: string) {
  const response = await fetch(imageURL, {
    method: 'HEAD',
  });

  if (response.status === 200) {
    return true;
  } else {
    return false;
  }
}

const MenuLogo: FC<BrandComponentProps> = ({ operatorId, className }) => {
  const [isChecking, setIsChecking] = useState(false);
  const [imageURL, setImageURL] = useState('');
  const lagoonIcon = 'public/img/lagoon-logo-cl.svg';

  if (!isChecking && imageURL === '' && operatorId && operatorId.endsWith('-PRO')) {
    setIsChecking(true);
    const url = 'https://soracom-customer-images.s3.amazonaws.com/lagoon/' + operatorId + '/logo';
    imageExists(url)
      .then((result) => {
        if (result) {
          setImageURL(url);
        } else {
          setImageURL(lagoonIcon);
        }
      })
      .finally(() => {
        setIsChecking(false);
      });
  } else if (operatorId && !operatorId.endsWith('-PRO')) {
    return <img className={className} src={lagoonIcon} alt="Lagoon" />;
  }

  if (imageURL === '') {
    return null;
  }
  return <img className={className} src={imageURL} alt="Lagoon" />;
};

const LoginBoxBackground = () => {
  const theme = useTheme();
  return css`
    background: ${theme.isLight ? 'rgba(6, 30, 200, 0.1 )' : 'rgba(18, 28, 41, 0.65)'};
    background-size: cover;
  `;
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'SORACOM Lagoon';
  static LoginTitle = 'SORACOM Lagoon';
  static GetLoginSubTitle = () => {
    const slogans = [
      "Don't get in the way of the data",
      'Your single pane of glass',
      'Built better together',
      'Democratising data',
    ];
    const count = slogans.length;
    return slogans[Math.floor(Math.random() * count)];
  };
}
