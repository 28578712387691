import React, { FC } from 'react';
import { css } from 'emotion';
import { GrafanaTheme } from '@grafana/data';
import { stylesFactory, useTheme } from '@grafana/ui';
import lightBackground from './img/background_light.svg';

const helpOptions = [
  { value: 0, label: 'Documentation', href: 'https://developers.soracom.io/en/docs/lagoon' },
  { value: 1, label: 'ドキュメント', href: 'https://users.soracom.io/ja-jp/docs/lagoon-v2' },
  { value: 2, label: 'Console', href: 'https://console.soracom.io' },
];

export const WelcomeBanner: FC = () => {
  const styles = getStyles(useTheme());

  return (
    <div className={styles.topcontainer}>
      <div className={styles.container}>
        <h1 className={styles.title}>Welcome to Lagoon</h1>
        <div className={styles.help}>
          <h3 className={styles.helpText}>Need help?</h3>
          <div className={styles.helpLinks}>
            {helpOptions.map((option, index) => {
              return (
                <a
                  key={`${option.label}-${index}`}
                  className={styles.helpLink}
                  href={`${option.href}?utm_source=lagoon_gettingstarted`}
                >
                  {option.label}
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.deprecationBanner}>
        Soracom Lagoon 2 service has been suspended and is in view only mode. Please{' '}
        <a
          className={styles.deprecationLink}
          href="https://developers.soracom.io/en/docs/lagoon/versions-and-migration/#migrating-to-soracom-lagoon-3"
        >
          migrate
        </a>{' '}
        to Lagoon 3, which has{' '}
        <a className={styles.deprecationLink} href="https://changelog.soracom.io/soracom-lagoon-3-release-28U7Ti">
          various useful functions
        </a>
        . Please get in touch with your administrator regarding migration.
        <br />
        Soracom Lagoon 2 サービスは停止され、現在は閲覧専用モードになっています。{' '}
        <a
          className={styles.deprecationLink}
          href="https://changelog.soracom.io/ja/soracom-lagoon-3-noti-gong-wokai-shi-simasita-28U7Ti"
        >
          様々な便利な機能
        </a>{' '}
        を備えた Lagoon 3 に
        <a className={styles.deprecationLink} href="https://users.soracom.io/ja-jp/docs/lagoon-v3/migration-guide/">
          移行してください
        </a>
        。移行の詳細については、管理者にお問い合わせください。
      </div>
    </div>
  );
};

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  const backgroundImage = theme.isDark ? 'public/img/login_background_dark.svg' : lightBackground;

  return {
    topcontainer: css`
      display: flex;
      background: url(${backgroundImage}) no-repeat;
      background-size: cover;
      height: 100%;
      align-items: stretch;
      padding: 0 16px;
      justify-content: space-between;
      padding: 0 ${theme.spacing.lg};
      flex-direction: column;
      overflow: hidden;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        background-position: 0px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        padding: 0 ${theme.spacing.sm};
      }
    `,
    container: css`
      display: flex;
      background: url(${backgroundImage}) no-repeat;
      background-size: cover;
      height: 100%;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        background-position: 0px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        padding: 0 ${theme.spacing.sm};
      }
    `,
    title: css`
      margin-bottom: 0;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        margin-bottom: ${theme.spacing.sm};
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        font-size: ${theme.typography.heading.h2};
      }
      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        font-size: ${theme.typography.heading.h3};
      }
    `,
    help: css`
      display: flex;
      align-items: baseline;
    `,
    helpText: css`
      margin-right: ${theme.spacing.md};
      margin-bottom: 0;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        font-size: ${theme.typography.heading.h4};
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        display: none;
      }
    `,
    helpLinks: css`
      display: flex;
      flex-wrap: wrap;
    `,
    helpLink: css`
      margin-right: ${theme.spacing.md};
      text-decoration: underline;
      text-wrap: no-wrap;

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        margin-right: 8px;
      }
    `,
    deprecationBanner: css`
      width: 100%;
      text-align: left;
      margin-bottom: 10px;
    `,
    deprecationLink: css`
      color: #34cdd7;
      text-decoration: underline;
    `,
  };
});
