import defaultTheme, { commonColorsPalette, soracomColorsPalette } from './default';
import { GrafanaThemeType, GrafanaTheme } from '@grafana/data';

const basicColors = {
  ...commonColorsPalette,
  black: '#000000',
  white: '#ffffff',
  dark1: '#1e2028',
  dark2: '#41444b',
  dark3: '#303133', // not used in light theme
  dark4: '#35373f', // not used in light theme
  dark5: '#41444b', // not used in light theme
  dark6: '#41444b', // not used in light theme
  dark7: '#41444b', // not used in light theme
  dark8: '#2f2f32', // not used in light theme
  dark9: '#343436', // not used in light theme
  dark10: '#424345', // not used in light theme
  gray1: '#52545c',
  gray2: '#767980',
  gray3: '#acb6bf',
  gray4: '#c7d0d9',
  gray5: '#dde4ed',
  gray6: '#e9edf2', // same as gray95
  gray7: '#f7f8fa', // same as gray98
  redBase: '#e02f44',
  redShade: '#c4162a',
  greenBase: '#3eb15b',
  greenShade: '#369b4f',
  red: '#d44939',
  yellow: '#ff851b',
  purple: soracomColorsPalette.magenta600,
  orange: soracomColorsPalette.orange,
  orangeDark: '#ed5700',
};

const backgrounds = {
  bg1: basicColors.white,
  bg2: soracomColorsPalette.gray50,
  bg3: soracomColorsPalette.gray200,
  dashboardBg: soracomColorsPalette.gray50,
  bgBlue1: soracomColorsPalette.celeste,
  bgBlue2: soracomColorsPalette.celeste,
};

const borders = {
  border1: soracomColorsPalette.gray200,
  border2: soracomColorsPalette.gray300,
  border3: soracomColorsPalette.gray400,
};

const textColors = {
  // Text colors
  textStrong: soracomColorsPalette.gray800,
  text: soracomColorsPalette.gray600,
  textSemiWeak: soracomColorsPalette.gray600,
  textWeak: soracomColorsPalette.gray,
  textFaint: soracomColorsPalette.gray400,
  textBlue: soracomColorsPalette.blue400,
};

const form = {
  formLabel: textColors.text,
  formDescription: textColors.textWeak,
  formLegend: soracomColorsPalette.gray700,
  formInputBg: basicColors.white,
  formInputBgDisabled: soracomColorsPalette.gray100,
  formInputBorder: soracomColorsPalette.gray300,
  formInputBorderHover: soracomColorsPalette.gray400,
  formInputBorderActive: soracomColorsPalette.celeste,
  formInputBorderInvalid: soracomColorsPalette.red600,
  formInputText: textColors.text,
  formInputPlaceholderText: textColors.textFaint,
  formInputDisabledText: textColors.textWeak,
  formFocusOutline: soracomColorsPalette.blue300,
  formValidationMessageText: basicColors.white,
  formValidationMessageBg: soracomColorsPalette.red600,
  formSwitchBg: soracomColorsPalette.gray300,
  formSwitchBgActive: soracomColorsPalette.celeste,
  formSwitchBgHover: basicColors.gray3,
  formSwitchBgActiveHover: soracomColorsPalette.celeste,
  formSwitchBgDisabled: basicColors.gray4,
  formSwitchDot: basicColors.white,
  formCheckboxBgChecked: soracomColorsPalette.celeste,
  formCheckboxBgCheckedHover: soracomColorsPalette.celeste,
  formCheckboxCheckmark: basicColors.white,
};

const lightTheme: GrafanaTheme = {
  ...defaultTheme,
  type: GrafanaThemeType.Light,
  isDark: false,
  isLight: true,
  name: 'Grafana Light',
  palette: {
    ...basicColors,
    brandPrimary: soracomColorsPalette.celeste,
    brandSuccess: soracomColorsPalette.green,
    brandWarning: soracomColorsPalette.orange,
    brandDanger: soracomColorsPalette.red,
    queryRed: soracomColorsPalette.red,
    queryGreen: soracomColorsPalette.green400,
    queryPurple: soracomColorsPalette.magenta400,
    queryOrange: soracomColorsPalette.orange,
    online: soracomColorsPalette.green,
    warn: soracomColorsPalette.orange,
    critical: soracomColorsPalette.red,
  },
  colors: {
    ...backgrounds,
    ...borders,
    ...form,
    ...textColors,

    bodyBg: backgrounds.bg1,
    panelBg: backgrounds.bg1,
    pageHeaderBg: backgrounds.bg2,
    pageHeaderBorder: borders.border1,
    panelBorder: borders.border1,

    dropdownBg: form.formInputBg,
    dropdownShadow: basicColors.gray3,
    dropdownOptionHoverBg: backgrounds.bg2,

    // Link colors
    link: textColors.text,
    linkDisabled: textColors.textWeak,
    linkHover: textColors.textStrong,
    linkExternal: soracomColorsPalette.blue400,
    textHeading: soracomColorsPalette.gray700,
  },
  shadows: {
    listItem: 'none',
  },
};

export default lightTheme;
